import { graphql } from "gatsby";
import React from "react";

export default function Kultur({ data }) {
  return <></>;
}

export const query = graphql`
  query {
    queryKultur: allSanityKultur(
      sort: { fields: eventDate, order: ASC }
      filter: { language: { eq: "de" } }
    ) {
      nodes {
        typeOfEvent
        place
        id
        eventDate
        linkEvent
        image {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        _rawText(resolveReferences: { maxDepth: 10 })
        title
      }
    }
    seo: allSanitySeo(
      filter: { title: { eq: "Programm" }, language: { eq: "de" } }
    ) {
      nodes {
        id
        image {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        title
        titlePage
        description
      }
    }
    queryShowHidePlaces: allSanityShowPlacesProgramm {
      nodes {
        klubShowProgramm
        klubShowLocation
        klubShowMieten
        saalShow
        wiengartenShow
        biergartenShow
      }
    }
  }
`;
